/* scrollbar.css */
body {
  --scrollbarBG: #37333F;
  --thumbBG: #E7E0F0;
}

/* Chrome, Edge, Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-button {
  display: none;
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.MuiList-root li{
  font-size: 0.875rem;
}

.MuiSvgIcon-root[data-testid="CloseIcon"] {
  color: #7A7488
}