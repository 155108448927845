.MuiDrawer-root {
    z-index: 9 !important;
    > div {
        z-index: 9 !important;
        background-color: #211E29;
        > div {
            width: 225px;
            margin: 12px;
            padding-bottom: 0;
            overflow: hidden;
        }
        
    }
}

.chrome-extension {
    > div {
        width: 80px;
        > div {
            width: calc(100% - 24px);
            padding: 12px;
            margin: 0;
        }
    }
}