@import './../../styles/ColorsConst.scss';

.bugDescription {
    font-family: "Roboto";
    width: 100%;
    outline: none;
    height: 160px;
    background: transparent;
    overflow: hidden auto;
    padding: 4px;
    border-radius: 4px;
    resize: none;
    border: 1px solid $SCHEMES_OUTLINE;
    color: $ON_SURFACE;
    
    //  ColorsConst.SCHEMES_OUTLINE,
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}